<template>
	<div class="animated fadeIn">
		<b-card>
			<div class="display-table full-width mb-2">
				<strong class="card-title">{{$route.meta.label}}</strong>
			</div>
			<b-row>
				<ResponseAlert ref="response" />
				<div class="col-sm-12 mb-10">
					<b-row class="align-items-center justify-content-end">
						<b-col sm="2">
							<b-form-select v-model="config.additional_params.contributor_status" :options="masters.statuses"></b-form-select>
						</b-col>
						<b-col sm="3">
							<div class="input-group">
								<input type="text" placeholder="Search name or email" v-model="config.search.key" v-on:keyup.enter="get()"
									class="form-control form-control-sm" />
								<div class="input-group-prepend">
									<div class="input-group-text" v-on:click="get()">
										<i class="fa fa-search"></i>
									</div>
								</div>
							</div>
						</b-col>
					</b-row>
				</div>
				<b-col sm="12">
					<div class="table-responsive">
						<table class="table b-table">
							<thead>
								<tr>
									<th v-for="(row, key) in config.headers" :width="row.width" :key="key"
										:class="row.class">
										<a v-if="row.value" class="sort dark-font" v-on:click="sort(row.value)"
											href="javascript:void(0)">
											{{row.title}}
											<i :id="row.value" class="fa fa-sort"></i>
										</a>
										<a v-else class="sort dark-font" :id="row.value" href="javascript:void(0)">
											{{row.title}}
										</a>
									</th>
								</tr>
							</thead>
							<tbody>
								<template v-if="config.total_data">
									<tr v-for="(row, key) in config.rows" :key="key">
										<td>{{row.first_name}} {{row.last_name}}</td>
										<td>{{truncate(row.email, 20)}}</td>
										<td>{{row.gender}}</td>
										<td>{{row.total_article}}</td>
										<td>{{moment(row.contributor_registered_at).format('DD/MM/YYYY')}}</td>
										<td>
											<span :class="'badge badge-' + getClassStatus(row.contributor_status)">
												{{row.contributor_status}}
											</span>
										</td>
										<td align="center">
											<button v-if="row.contributor_status === 'waiting approval'" @click="changeStatus('active', row.id)" title="Approve" class="btn-action">
												<i class="fa fa-check text-success"></i>
											</button>
											<button v-if="row.contributor_status === 'waiting approval'" title="Reject" class="btn-action" @click="openModalReject('reject', row.id)">
												<i class="fa fa-close text-danger"></i>
											</button>
											<router-link :to='`/${config.uri}/${row.id}`' title="Detail"
												class="btn-action">
												<i class="fa fa-eye"></i>
											</router-link>
										</td>
									</tr>
								</template>
								<tr v-if="!config.total_data">
									<td :colspan="config.headers.length" align="center"> No data available. </td>
								</tr>
							</tbody>
							<tfoot v-if="config.total_data">
								<tr>
									<td :colspan="config.headers.length" class="no-padding">
										<div class="pull-left table-information mt-25">
											<p>Showing {{config.page}} to {{config.total_page}} of {{config.total_data}}
												entries</p>
										</div>
										<div class="pull-right pagination mt-25">
											<b-pagination @change="gotoPage" :total-rows="config.total_data"
												v-model="config.page" :per-page="config.per_page">
											</b-pagination>
										</div>
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</b-col>
			</b-row>
			<b-modal id="modal-1" title="Reject Reason" centered hide-footer>
				<b-form-group v-bind:class="{ 'is-invalid': errors.has('reject reason')}" label="Reject Reason">
					<b-form-textarea
						v-model="models.rejectReason"
						v-validate="'required'"
						data-vv-name="reject reason"
						rows="3"
						max-rows="6"
					></b-form-textarea>
					<div v-if="errors.has('reject reason')">
						<p class="text-danger">{{ errors.first('reject reason') }}</p>
					</div>
				</b-form-group>
				<div class="d-flex">
					<b-button @click="$bvModal.hide('modal-1')" class="w-50 m-auto d-block">Cancel</b-button>
					<b-button @click="rejectContributor()" class="w-50 m-auto d-block btn btn-primary text-white">Submit</b-button>
				</div>
			</b-modal>
		</b-card>
	</div>
</template>
<script>
	import table from '@/utils/table.js'
	import api from '@/utils/api.js'
	import ResponseAlert from '@/components/response_alert';
	import moment from 'moment'
	import { getClassStatus, truncate } from '@/utils/helpers';
	export default {
		name: 'ListPackage',
		components: {
			ResponseAlert,
		},
		data() {
			return {
				moment,
				truncate,
				config: {
					uri: this.$route.meta.permission.link,
					api: api.user,
					rows: [],
					total_data: 0,
					total_page: 0,
					per_page: 10,
					page: 1,
					orderby: "id",
					sort: "desc",
					additional_params: {
						contributor_status: null,
						role_id: 1,
						is_contributor: true
					},
					search: {
						key: ""
					},
					headers: [{
						title: "Full Name",
						value: "first_name",
						align: "text-left",
						width: "20%",
					}, {
						title: "Email",
						value: "email",
						align: "text-left",
						width: "15%",
					}, {
						title: "Gender",
						value: false,
						align: "text-left",
						width: "7%",
					}, {
						title: "Total Article",
						value: false,
						align: "text-left",
						width: "10%",
					},{
						title: "Registered At",
						value: false,
						align: "text-left",
						width: "15%",
					}, {
						title: "Status",
						value: "contributor_status",
						align: "text-left",
						width: "10%",
					}, {
						title: "Action",
						value: false,
						class: "text-center",
						width: "18%",
					}],
				},
				masters: {
					statuses: [
						{ value: null, text: 'Status' },
						{ value: 'active', text: 'Active' },
						{ value: 'inactive', text: 'Inactive' },
						{ value: 'reject', text: 'Reject' },
						{ value: 'waiting approval', text: 'Waiting Approval' },
					]
				},
				models: {
					rejectReason: '',
					selectedContributor: null
				}
			}
		},
		created() {
			const _ = this;
			_.get();
		},
		methods: {
			get() {
				table.get(this.config)
			},
			sort(orderby) {
				table.sort(this.config, orderby)
			},
			gotoPage(page) {
				table.gotoPage(this.config, page)
			},
			getClassStatus(status) {
				return getClassStatus(status);
			},

			openModalReject(status, id) {
				this.models.selectedContributor = {
					contributor_status: status,
					userId: id
				}
				this.$bvModal.show('modal-1')
			},

			rejectContributor(){
				const _ = this
				_.$validator.validate().then(valid => {
					if (valid) {
						const {contributor_status, userId} = this.models.selectedContributor
						this.changeStatus(contributor_status, userId, _.models.rejectReason)
						this.$bvModal.hide('modal-1')
						setTimeout(() => {
							this.models.rejectReason = ''
							this.models.selectedContributor = null
						}, 1000);
					} else {
						this.models.selectedContributor = null
						window.scrollTo(0, 0);
					}
				});
			},

			changeStatus(contributor_status, userId, reason) {
        const _ = this
				const data = {
					contributor_status
				}

				if(reason) {
					data.reject_reason = reason
				}

				_.$axios.put(`${api.user}/contributor-status/${userId}`, data)
          .then(res => {
            _.$refs.response.setResponse(res.data.message, 'success');
            _.get()
          })
          .catch(error => {
            _.$refs.response.setResponse(error.response.data.message, 'danger')
          })
      },
		},
		computed: {
			status() {
				return this.config.additional_params.contributor_status
			}
		},
		watch: {	
			status() {
				this.get()
			}
		}
	}
</script>